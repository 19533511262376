import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import PortifolioApp from '@/PortifolioApp.vue';
import HomeView from '@/views/HomeView/HomeView.vue';
import NotFound from '@/views/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    component: PortifolioApp,
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { title: 'Rodrigo Silva' },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Não encontrado :(' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta.title as string;
});

export default router;
