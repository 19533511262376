
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBarChip',
  props: {
    label: String,
    isSelected: Boolean,
    animationDelay: Number,
  },
  computed: {
    chipDynamicStyle(): string {
      if (this.animationDelay) {
        return `--animation-delay: ${this.animationDelay}ms`;
      }

      return '--animation-delay: 200ms';
    },
  },
});
