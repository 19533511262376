
import { defineComponent } from 'vue';

import TitleAnimated from './TitleAnimated.vue';

export default defineComponent({
  name: 'PageTitle',
  components: {
    TitleAnimated,
  },
  data() {
    return {
      roles: [
        'Software engineer',
        'Product designer',
        'Solutions architect',
      ],
      roleIndex: 0,
    };
  },
  mounted() {
    setInterval(this.changeText, 3000);
  },
  computed: {
    currentText(): string {
      return this.roles[this.roleIndex];
    },
  },
  methods: {
    changeText(): void {
      this.roleIndex = (this.roleIndex + 1) % this.roles.length;
    },
  },
});
