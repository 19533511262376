
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FavoriteTechs',
  methods: {
    getAnimationSettings(index: number): string {
      return `--animation-delay: ${((4 - index) * 100) + 250}ms; --slide-target: ${index * 60}px;`;
    },
  },
});
