
import { defineComponent } from 'vue';

import NavBar from './components/NavBar.vue';

export default defineComponent({
  name: 'PortifolioApp',
  components: {
    NavBar,
  },
});
