
import { defineComponent } from 'vue';

import NavBarChip from './NavBarChip.vue';

export default defineComponent({
  name: 'NavBar',
  components: {
    NavBarChip,
  },
});
