import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    name: "drop-in"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: _normalizeStyle(_ctx.chipDynamicStyle),
        class: _normalizeClass([
        'chip',
        'gradesk-px-sm',
        'gradesk-py-xxs',
        { active: _ctx.isSelected }
      ])
      }, _toDisplayString(_ctx.label), 7)
    ]),
    _: 1
  }))
}