
import { defineComponent } from 'vue';

import PageTitle from './components/PageTitle.vue';
import FavoriteTechs from './components/FavoriteTechs.vue';
import AboutMe from './components/AboutMe.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    PageTitle,
    FavoriteTechs,
    AboutMe,
  },
});
