import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gradesk-row gradesk-cg-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBarChip = _resolveComponent("NavBarChip")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_NavBarChip, {
      animationDelay: 200,
      label: "Home",
      isSelected: ""
    }),
    _createVNode(_component_NavBarChip, {
      animationDelay: 300,
      label: "Portifolio"
    }),
    _createVNode(_component_NavBarChip, {
      animationDelay: 400,
      label: "Projects"
    }),
    _createVNode(_component_NavBarChip, {
      animationDelay: 500,
      label: "Professional experience"
    })
  ]))
}