import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95f49d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 gradesk-row section-height main-axis-alignment_center" }
const _hoisted_2 = { class: "gradesk-row introduction-section h-100 w-100 main-axis-alignment_space-between" }
const _hoisted_3 = { class: "gradesk-column main-axis-alignment_center" }
const _hoisted_4 = { class: "gradesk-row" }
const _hoisted_5 = { class: "gradesk-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_FavoriteTechs = _resolveComponent("FavoriteTechs")!
  const _component_AboutMe = _resolveComponent("AboutMe")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_PageTitle)
        ]),
        _createVNode(_component_FavoriteTechs, { class: "gradesk-mt-xxxl" })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AboutMe)
      ])
    ])
  ]))
}