import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "base-container",
  class: "gradesk-column gradesk-pa-md"
}
const _hoisted_2 = { class: "gradesk-row main-axis-alignment_space-between gradesk-mb-md" }
const _hoisted_3 = { class: "gradesk-row gradesk-flex-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NavBar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_RouterView)
    ])
  ]))
}